@keyframes move-eye-skew {
  0% {
    transform: none;
  }

  20% {
    transform: translateX(-68px) translateY(30px) skewX(15deg) skewY(-10deg)
      scale(0.95);
  }

  25%,
  44% {
    transform: none;
  }

  50%,
  60% {
    transform: translateX(68px) translateY(-40px) skewX(5deg) skewY(2deg)
      scaleX(0.95);
  }

  66%,
  100% {
    transform: none;
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes rotate360 {
  0% {
    transform: rotateY(0deg) translateZ(195px) scale(1);
  }
  50% {
    transform: rotateY(180deg) translateZ(100px) scale(0.5);
  }
  100% {
    transform: rotateY(360deg) translateZ(197.5px) scale(1);
  }
}

@keyframes rotateToFront {
  0% {
    transform: rotateY(180deg) translateZ(100px) scale(0.5);
  }
  100% {
    transform: rotateY(0deg) translateZ(195px) scale(1);
  }
}

@keyframes rotateToFrontBis {
  0% {
    transform: rotateY(180deg) translateZ(100px) scale(0.5);
  }

  100% {
    transform: rotateY(360deg) translateZ(195px) scale(1);
  }
}

@keyframes rotate8 {
  from {
    height: 130px;
  }

  to {
    height: 140px;
  }
}

main {
  background: linear-gradient(
    to bottom,
    rgba(100, 100, 100, 0.2) 0%,
    rgba(255, 255, 255, 0.5) 40%,
    #ffffff 100%
  );
  background-repeat: no-repeat;
}

.magicEightBall {
  display: flex;
  flex-direction: column;
  align-items: center;
}

input {
  border: none;
  padding: 10px;
  border-radius: 4px;
  font-size: 35px;
  min-width: 400px;
}
input:focus-visible {
  outline: black solid 1px;
}

.ball {
  cursor: pointer;
  display: inline-block;
  width: 100%;
  height: 100%;
  margin: 0;
  border-radius: 50%;
  position: relative;
  background: radial-gradient(
    circle at 50% 120%,
    #323232,
    #0a0a0a 80%,
    #000000 100%
  );
  border: none;
  box-shadow: 0px 55px 45px -38px rgba(0, 0, 0, 0.3);
  overflow: hidden;
}

.ball.click-state .eight {
  animation: rotateToFront 5s linear 1 reverse forwards;
}

.ball.base-state .eight {
  animation: rotateToFrontBis 3s linear 1 normal forwards;
}

.ball:hover {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  /*perspective: 1000px;*/
}

.ball:before {
  content: "";
  position: absolute;
  background: radial-gradient(
    circle at 50% 120%,
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0) 70%
  );
  border-radius: 50%;
  bottom: 2.5%;
  left: 5%;
  opacity: 0.6;
  height: 100%;
  width: 90%;
  -webkit-filter: blur(5px);
  filter: blur(5px);
  z-index: 12;
  position: absolute;
}

.ball:after {
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  top: 5%;
  left: 10%;
  border-radius: 50%;
  background: radial-gradient(
    circle at 50% 50%,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0.8) 14%,
    rgba(255, 255, 255, 0) 24%
  );
  -webkit-transform: translateX(-80px) translateY(-90px) skewX(-20deg);
  -moz-transform: translateX(-80px) translateY(-90px) skewX(-20deg);
  -ms-transform: translateX(-80px) translateY(-90px) skewX(-20deg);
  -o-transform: translateX(-80px) translateY(-90px) skewX(-20deg);
  transform: translateX(-80px) translateY(-90px) skewX(-20deg);
  -webkit-filter: blur(10px);
  filter: blur(10px);
}

.ballContent {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ballContent > span {
  flex: 1;
}

.ball .eight {
  height: 140px;
  margin: 0;
  background: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: calc(30% - 10px);
  width: 140px;

  z-index: 1;
  position: relative;

  text-align: center;
  white-space: nowrap;
  backface-visibility: hidden;
}

.ball .eight:before {
  content: "8";
  display: block;
  position: absolute;
  text-align: center;
  width: 100px;
  color: black;
  font-family: Arial;
  font-size: 90px;
  line-height: 104px;
}

.ball .shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  z-index: 12;
  box-shadow: inset -7px -5px 32px 18px rgb(255 247 247 / 38%);
  border-radius: 50%;
}

.stage {
  width: 300px;
  height: 300px;
  display: inline-block;
  margin: 0;
  -webkit-perspective: 1200px;
  -moz-perspective: 1200px;
  -ms-perspective: 1200px;
  -o-perspective: 1200px;
  perspective: 1200px;
  -webkit-perspective-origin: 50% 50%;
  -moz-perspective-origin: 50% 50%;
  -ms-perspective-origin: 50% 50%;
  -o-perspective-origin: 50% 50%;
  perspective-origin: 50% 50%;
}

span.answer {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 700;
  font-size: 2rem;
  max-width: 80%;
  top: -50%;
  left: -25%;
  margin: 0 auto;
  position: relative;
  backface-visibility: hidden;
}

span.answer.base-state {
  /*animation: rotateToFrontBis 5s linear 1 normal forwards;*/
  height: 0;
  visibility: hidden;
}
span.answer.click-state {
  animation: rotateToFrontBis 3s linear 1 normal forwards;
  height: 100%;
  visibility: visible;
}

/*
.ball .eight {
  text-align: center;
  white-space: nowrap;
  animation: rotate 5s linear;
  backface-visibility: hidden;
}
*/
